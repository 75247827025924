:root .modal-box-container .modal-box-buttons button {
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
}

:root .modal-box-container {
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
}

:root .modal-box-container .modal-box-buttons button {
  font-size: 0.8em;
  padding: 0.75em 2em;
  margin: 0 1em;
  text-transform: uppercase;
  color: var(--sim-color-gray);
  background-color: transparent;
  border: 1px solid var(--sim-color-gray);
}

/*
%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%host{
  width: calc(100% - 10em);
  height: calc(100% - 10em);
  position:absolute;
  left:7em;
  top:8.5em;
  justify-content: inherit;
}

%appearance-none {
  appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

%font-expanded{
  font-size: 0.75em;
  text-transform: uppercase;
  font-stretch: expanded;
  font-weight: bold;
}
*/
:root {
  --modal-box-base-color: var(--sim-color-black-rgb);
  --modal-box-base-opacity: 0.75;
  --modal-box-background: var(--sim-color-black);
  --modal-box-color: var(--sim-color-gray);
  --modal-box-success-background: var(--alert-color-green);
  --modal-box-success-color: var(--sim-color-white);
  --modal-box-warning-background: var(--alert-color-red);
  --modal-box-warning-color: var(--sim-color-white);
}
:root .modal-box-container {
  border: 1px solid var(--sim-color-gray);
  width: auto !important;
}
:root .modal-box-container .modal-box-buttons {
  margin: 0;
  font-size: 1.25em;
  white-space: nowrap;
}
:root .modal-box-container.warning, :root .modal-box-container.success {
  border: 1px solid var(--sim-color-white);
}
:root .modal-box-container.warning button, :root .modal-box-container.success button {
  color: var(--sim-color-white);
  border: 1px solid var(--sim-color-white);
}