@import "./hvita/mixins";

:root {
  --modal-box-base-color: var(--sim-color-black-rgb);
  --modal-box-base-opacity: 0.75;
  --modal-box-background: var(--sim-color-black);
  --modal-box-color: var(--sim-color-gray);
  --modal-box-success-background: var(--alert-color-green);
  --modal-box-success-color: var(--sim-color-white);
  --modal-box-warning-background: var(--alert-color-red);
  --modal-box-warning-color: var(--sim-color-white);

  .modal-box-container{
    border:1px solid var(--sim-color-gray);
    width: auto !important;
    @extend %border-radius-medium;

    .modal-box-buttons{
      margin: 0;
      font-size:1.25em;
      white-space: nowrap;

      button{
        @extend %common-button;
      }
    }

    &.warning,
    &.success{
      border:1px solid var(--sim-color-white);

      button{
        color: var(--sim-color-white);
        border: 1px solid var(--sim-color-white);
      }
    }
  }
}
