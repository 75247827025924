:root .wireless-form-container input, :root .wireless-container button {
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
}

:root .wireless-container button {
  font-size: 0.8em;
  padding: 0.75em 2em;
  margin: 0 1em;
  text-transform: uppercase;
  color: var(--sim-color-gray);
  background-color: transparent;
  border: 1px solid var(--sim-color-gray);
}

/*
%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%host{
  width: calc(100% - 10em);
  height: calc(100% - 10em);
  position:absolute;
  left:7em;
  top:8.5em;
  justify-content: inherit;
}

%appearance-none {
  appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

%font-expanded{
  font-size: 0.75em;
  text-transform: uppercase;
  font-stretch: expanded;
  font-weight: bold;
}
*/
:root {
  --wireless-fore-color: var(--sim-color-white);
  --wireless-highlight-color: var(--sim-color-white);
  --wireless-connect: var(--sim-color-dark-gray);
  --wireless-disconnect: var(--sim-color-red);
  --wireless-warning: var(--alert-color-red);
}
:root .wireless-form-container {
  margin-bottom: 2em;
}
:root .wireless-form-container input {
  color: var(--sim-color-light-gray) !important;
  border: 1px solid var(--sim-color-light-gray) !important;
}