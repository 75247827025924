@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-BlackItalic.eot");
  src: url("./fonts/Lato-BlackItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-BlackItalic.woff2") format("woff2"), url("./fonts/Lato-BlackItalic.woff") format("woff"), url("./fonts/Lato-BlackItalic.ttf") format("truetype"), url("./fonts/Lato-BlackItalic.svg#Lato-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Bold.eot");
  src: url("./fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Bold.woff2") format("woff2"), url("./fonts/Lato-Bold.woff") format("woff"), url("./fonts/Lato-Bold.ttf") format("truetype"), url("./fonts/Lato-Bold.svg#Lato-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Black.eot");
  src: url("./fonts/Lato-Black.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Black.woff2") format("woff2"), url("./fonts/Lato-Black.woff") format("woff"), url("./fonts/Lato-Black.ttf") format("truetype"), url("./fonts/Lato-Black.svg#Lato-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Light.eot");
  src: url("./fonts/Lato-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Light.woff2") format("woff2"), url("./fonts/Lato-Light.woff") format("woff"), url("./fonts/Lato-Light.ttf") format("truetype"), url("./fonts/Lato-Light.svg#Lato-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Regular.eot");
  src: url("./fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Regular.woff2") format("woff2"), url("./fonts/Lato-Regular.woff") format("woff"), url("./fonts/Lato-Regular.ttf") format("truetype"), url("./fonts/Lato-Regular.svg#Lato-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato Hairline";
  src: url("./fonts/Lato-HairlineItalic.eot");
  src: url("./fonts/Lato-HairlineItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-HairlineItalic.woff2") format("woff2"), url("./fonts/Lato-HairlineItalic.woff") format("woff"), url("./fonts/Lato-HairlineItalic.ttf") format("truetype"), url("./fonts/Lato-HairlineItalic.svg#Lato-HairlineItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Italic.eot");
  src: url("./fonts/Lato-Italic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Italic.woff2") format("woff2"), url("./fonts/Lato-Italic.woff") format("woff"), url("./fonts/Lato-Italic.ttf") format("truetype"), url("./fonts/Lato-Italic.svg#Lato-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-BoldItalic.eot");
  src: url("./fonts/Lato-BoldItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-BoldItalic.woff2") format("woff2"), url("./fonts/Lato-BoldItalic.woff") format("woff"), url("./fonts/Lato-BoldItalic.ttf") format("truetype"), url("./fonts/Lato-BoldItalic.svg#Lato-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lato Hairline";
  src: url("./fonts/Lato-Hairline.eot");
  src: url("./fonts/Lato-Hairline.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Hairline.woff2") format("woff2"), url("./fonts/Lato-Hairline.woff") format("woff"), url("./fonts/Lato-Hairline.ttf") format("truetype"), url("./fonts/Lato-Hairline.svg#Lato-Hairline") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-LightItalic.eot");
  src: url("./fonts/Lato-LightItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-LightItalic.woff2") format("woff2"), url("./fonts/Lato-LightItalic.woff") format("woff"), url("./fonts/Lato-LightItalic.ttf") format("truetype"), url("./fonts/Lato-LightItalic.svg#Lato-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
.scrollable::-webkit-scrollbar-track, .scrollable::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
}

/*
%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%host{
  width: calc(100% - 10em);
  height: calc(100% - 10em);
  position:absolute;
  left:7em;
  top:8.5em;
  justify-content: inherit;
}

%appearance-none {
  appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

%font-expanded{
  font-size: 0.75em;
  text-transform: uppercase;
  font-stretch: expanded;
  font-weight: bold;
}
*/
:root {
  /** simulator colors **/
  --sim-color-blue: #2DABE3;
  --sim-color-red: #D64D48;
  --sim-color-red-popup: #FF0000;
  --sim-color-dark-red: #850E15;
  --sim-color-gold: #FFFF00;
  --sim-color-marron: #817672;
  --sim-color-green: #3e4c4f;
  --sim-color-dark-blue: #2DABE3;
  --sim-color-white: #ffffff;
  --sim-color-black: #110F14;
  --sim-color-gray: #676A6F;
  --sim-color-light-gray: #CACBCC;
  --sim-color-medium-gray: #9C9B9A;
  --sim-color-dark-gray: #353438;
  --sim-color-transparent: transparent;
  --sim-color-error-bg: #f8d7da;
  --sim-color-error-border: #f5c6cb;
  --sim-color-tooltip-background: #ffffc8;
  --alert-color-red: #D64D48;
  --alert-color-green: #03a02c;
  --pat-color-green: #37EB49;
  --pat-color-red: #E4402D;
  --pat-color-yellow: #E9EC61;
  --pat-color-blue: #2682a9;
  --pat-color-purple: #fe00e9;
  --pat-color-white: #ffffff;
  --pat-color-gray: #989898;
  --pat-color-black: #110F14;
  --pat-color-danger-start: #e13838;
  --pat-color-danger-end: #7a1b1b;
  --keyboard-color-white: var(--sim-color-white);
  --keyboard-color-medium-gray: var(--sim-color-medium-gray);
  --keyboard-color-dark-gray: var(--sim-color-dark-gray);
  --keyboard-color-dark-blue: #343e68;
  --sim-color-black-rgb: 17, 15, 20;
  --sim-color-gray-rgb: 103, 106, 111;
  --sim-color-dark-gray-rgb: 53, 52, 56;
}

html {
  --ion-font-family: "Lato";
  --ion-text-color: var(--sim-color-white);
  --ion-background-color: var(--sim-color-black);
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.25;
  color: var(--sim-color-white);
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  line-height: 1.25;
  color: var(--sim-color-light-gray);
}

a {
  text-decoration: none;
  color: var(--sim-color-light-gray);
  cursor: pointer;
  margin: 0;
  padding: 0;
  line-height: 1.25;
}

input, textarea, select {
  outline: none;
}

span {
  color: var(--sim-color-white);
  line-height: 1.25;
}

.d-none {
  display: none !important;
}

.v-hidden {
  visibility: hidden !important;
}

.page-title {
  font-size: 2em;
  color: var(--sim-color-light-gray);
  margin-bottom: 1em;
  border-bottom: 1px solid var(--sim-color-dark-gray);
  padding-bottom: 0.25em;
}

.scrollable {
  overflow: hidden;
  overflow-y: auto;
  /*Estilos estándar experimentales (Principalmente Firefox)*/
  scrollbar-color: var(--sim-color-gray) transparent;
  scrollbar-width: thin;
  /* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
}
.scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
}
.scrollable::-webkit-scrollbar:vertical {
  width: 0.2em;
}
.scrollable::-webkit-scrollbar-button:increment, .scrollable::-webkit-scrollbar-button {
  display: none;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: var(--sim-color-gray);
}
.scrollable::-webkit-scrollbar-track {
  background: transparent;
}

.edit-stages .swiper .swiper-slide {
  font-size: 1.25em !important;
}

.edit-scenarios .swiper .swiper-slide {
  font-size: 0.9em !important;
}