
.modal-box-container{
  .version-box-message{
    margin-top: 1em !important;
    font-size:1.75em;

    width:22em !important;
  }

  .version-box-image{
    width:5em !important;
  }
}
