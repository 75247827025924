:root {
  --keyboard-modal-color: var(--keyboard-color-black);
  --keyboard-modal-opacity: 0;

  --keyboard-key-color: var(--keyboard-color-white);
  --keyboard-key-background:  var(--keyboard-color-medium-gray);
  --keyboard-key-pressed: var(--keyboard-color-dark-blue);
  --keyboard-key-border: var(--keyboard-color-white);

  --keyboard-background:  var(--keyboard-color-medium-gray);
  --keyboard-border: var(--keyboard-color-dark-gray);

  --keyboard-header-background: var(--keyboard-color-dark-gray);
  --keyboard-header-color: var(--keyboard-color-white);
}
